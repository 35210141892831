<!-- eslint-disable vue/valid-v-model -->
<!-- eslint-disable no-tabs -->
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title>
        {{ $t('stock.addNewProduct') }}
      </v-card-title>
      <v-row>
        <div class="col-md-6">
          <template>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submitForm()"
            >
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.product_date"
                          :label="$t('stock.date')"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          outlined
                          v-bind="attrs"
                          :rules="globalRules"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="data.product_date"
                        :min="today"
                        @input="menu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="data.branch_id"
                      :items="branches"
                      :label="$t('settings.branches')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      return-object
                      :rules="globalRules"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="data.metal_type"
                      :items="metalTypes"
                      :label="$t('filter.metalType')"
                      item-text="name"
                      item-value="id"
                      outlined
                      :rules="globalRules"
                      dense
                      @change="data.metal_type == 3 ? fetchAllCertificates() : null"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-file-input
                      chips
                      required
                      outlined
                      dense
                      prepend-icon="mdi-camera"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Image"
                      :rules="globalRules"
                      @change="uploadImags"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="data.category_id"
                      :items="categories"
                      :label="$t('settings.categories')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      return-object
                      :rules="globalRules"
                      @change="getcode(data.category_id)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      label="Label"
                      readonly
                      dense
                      outlined
                      :value="data.category_id.code && data.label ? data.category_id.code + data.label : ''"
                      :rules="globalRules"
                    />
                  </v-col>
                  <v-col
                    v-if="data.category_id && data.category_id.id != 6"
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="data.gold_weight"
                      label="Gold Weight (gm) "
                      dense
                      outlined
                      :rules="globalRules"
                    />
                  </v-col>
                  <v-col
                    v-if="data.category_id && data.category_id.id != 6"
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="data.gold_price"
                      label="Gold Price"
                      dense
                      outlined
                      :rules="globalRules"
                    />
                  </v-col>
                  <v-col
                    v-if="data.metal_type == 3"
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="data.certificate_type_id"
                      :items="certificates"
                      :label="$t('settings.certificates')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      return-object
                      :rules="globalRules"
                    />
                  </v-col>
                  <v-col
                    v-if="data.metal_type === 1 && (data.category_id && data.category_id.id != 6)"
                    cols="12"
                  >
                    <v-card class="pa-3">
                      <v-card-title>
                        STONE DETAILS
                      </v-card-title>
                      <v-card-text class="pa-3">
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="stoneDetails"
                              :items="stoneTypes"
                              :label="$t('settings.stone.stoneName')"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              return-object
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.quantity"
                              :label="$t('settings.stone.quantity')"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.weight"
                              :label="$t('settings.stone.weight')"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.price"
                              :label="$t('settings.stone.price')"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.setting"
                              :label="$t('settings.stone.setting')"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-btn
                            class="mx-auto my-auto d-flex"
                            color="indigo"
                            @click="AddNewStone(stoneDetails)"
                          >
                            {{ $t('actions.addStone') }}
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    v-if="data.category_id && data.category_id.id == 6"
                    cols="12"
                  >
                    <v-card class="pa-3">
                      <v-card-title>
                        STONE DETAILS
                      </v-card-title>
                      <v-card-text class="pa-3">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="stoneDetails.stone_id"
                              :label="$t('settings.stone.stoneId')"
                              dense
                              outlined
                              :rules="globalRules"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.stone_color"
                              :label="$t('settings.stone.color')"
                              dense
                              outlined
                              :rules="globalRules"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.stone_weight"
                              :label="$t('settings.stone.weight')"
                              dense
                              outlined
                              :rules="globalRules"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="stoneDetails.stone_setting"
                              :label="$t('settings.stone.clarity')"
                              dense
                              outlined
                              :rules="globalRules"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              class="mx-auto my-auto d-flex"
                              color="indigo"
                              :loading="loading"
                              :disabled="!valid || loading === true"
                              @click="AddNewStone(), submitForm()"
                            >
                              {{ this.$route.params.id ? $t('actions.save') : $t('actions.add') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </template>
        </div>
        <div class="col-md-6">
          <v-card>
            <v-card-title>
              {{ $t('stock.productDetails') }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <div class="col-md-6 image">
                  <img
                    v-if="data.image"
                    :src="data.image"
                    width="120"
                    height="120"
                    alt="product image"
                    class="product-img"
                  >
                  <div
                    v-else
                    class="noImage text-center"
                    rounded
                  >
                    {{ $t('stock.noImageAvialble') }}
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <b>{{ $t('stock.productName') }}</b>
                    <p>{{ data.category_id.name ? data.category_id.name + ' ' + data.category_id.code : '-' }}</p>
                  </div>
                  <div>
                    <b>
                      {{ $t('stock.branch') }}
                    </b>
                    <p>{{ data.branch_id.name ? data.branch_id.name : '-' }}</p>
                  </div>
                  <div>
                    <b>{{ $t('stock.date') }}</b>
                    <p>{{ data.product_date ? data.product_date : '-' }}</p>
                  </div>
                </div>
                <div
                  v-if="data.category_id && data.category_id.id != 6"
                  class="col-12"
                >
                  <v-row class="justify-space-between">
                    <v-card class="pa-3 ma-3 text-center width100">
                      <b>{{ $t('stock.goldWeight') }}</b>
                      (gm)
                      <p class="py-2">
                        {{ data.gold_weight }}
                      </p>
                    </v-card>
                    <v-card class="pa-3 ma-3 text-center width100">
                      <b>
                        {{ $t('stock.salePrice') }}
                      </b>
                      ($)
                      <p class="py-2">
                        {{ data.gold_price }}
                      </p>
                    </v-card>
                    <v-card class="pa-3 ma-3 text-center width100">
                      <b>{{ $t('stock.total') }}</b>
                      ($)
                      <p class="py-2">
                        {{ data.gold_weight * data.gold_price }}
                      </p>
                    </v-card>
                  </v-row>
                </div>
                <div
                  v-if="data.stones.length > 0"
                  class="col-12"
                >
                  {{ 'Stone Details ' + data.stones.length + ' stone' }}
                  <v-simple-table v-if="data.stones[0].name">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Name
                          </th>
                          <th class="text-center">
                            Quantity
                          </th>
                          <th class="text-center">
                            Weight (CTS)
                          </th>
                          <th class="text-center">
                            Cost ($)
                          </th>
                          <th class="text-center">
                            Setting
                          </th>
                          <th class="text-center">
                            Total
                          </th>
                          <th
                            class="text-center"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(item, i) in data.stones"
                          :key="i"
                        >
                          <td class="text-center">
                            <span>{{ item.name }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.quantity }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.weight }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.price }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.setting }}</span>
                          </td>
                          <td class="text-center">
                            <span>
                              {{ item.total = (+item.price * +item.weight) + +item.setting + +item.quantity }}
                            </span>
                          </td>
                          <td
                            class="text-center"
                          >
                            <v-tooltip
                              bottom
                            >
                              <template
                                v-slot:activator="{ on, attrs }"
                              >
                                <v-btn
                                  small
                                  fab
                                  outlined
                                  class="mx-2"
                                  color="blue"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="changeStone(item)"
                                >
                                  <v-icon>
                                    fa-pen
                                  </v-icon>
                                </v-btn>
                              </template>
                              {{ $t('actions.changeStone') }}
                            </v-tooltip>
                            <v-tooltip
                              bottom
                            >
                              <template
                                v-slot:activator="{ on, attrs }"
                              >
                                <v-btn
                                  small
                                  fab
                                  outlined
                                  class="mx-2"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteItem(item)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </template>
                              {{ $t('actions.delete') }}
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table v-if="data.stones.length > 0 && data.category_id && data.category_id.id == 6">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            stone ID
                          </th>
                          <th class="text-center">
                            Color
                          </th>
                          <th class="text-center">
                            Weight (CTS)
                          </th>
                          <th class="text-center">
                            Clarity
                          </th>
                          <!-- <th
                            class="text-center"
                          >
                            Action
                          </th> -->
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(item, i) in data.stones"
                          :key="i"
                        >
                          <td class="text-center">
                            <span>{{ item.stone_id }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.stone_color }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.stone_weight }}</span>
                          </td>
                          <td class="text-center">
                            <span>{{ item.stone_setting }}</span>
                          </td>
                          <!-- <td
                            class="text-center"
                          >
                            <v-tooltip
                              bottom
                            >
                              <template
                                v-slot:activator="{ on, attrs }"
                              >
                                <v-btn
                                  small
                                  fab
                                  outlined
                                  class="mx-2"
                                  color="blue"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="changeStone(item)"
                                >
                                  <v-icon>
                                    fa-pen
                                  </v-icon>
                                </v-btn>
                              </template>
                              {{ $t('actions.changeStone') }}
                            </v-tooltip>
                            <v-tooltip
                              bottom
                            >
                              <template
                                v-slot:activator="{ on, attrs }"
                              >
                                <v-btn
                                  small
                                  fab
                                  outlined
                                  class="mx-2"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="confirmDeleteItem(item)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </template>
                              {{ $t('actions.delete') }}
                            </v-tooltip>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div
                  v-if="data.category_id && data.category_id.id != 6"
                  class="col-md-6"
                >
                  <v-card
                    class="pa-3 text-center"
                    height="100"
                    color="grey"
                    dark
                  >
                    <h4>COST</h4>
                    {{ Math.ceil(( +data.gold_price * +data.gold_weight) + (data.stones.reduce(
                      (p, item) => p + ( (+item.price * +item.weight) + +item.setting + +item.quantity),
                      0
                    ))) }}
                  </v-card>
                </div>
                <div
                  v-if="data.category_id && data.category_id.id != 6"
                  class="col-md-6"
                >
                  <v-card
                    class="pa-3 text-center"
                    height="100"
                    color="grey"
                    dark
                  >
                    <h4 class="d-flex justify-center">
                      Final COST *
                      <div>
                        <v-text-field
                          v-model="data.profit_percent"
                          outlined
                          dense
                          class="width70"
                        />
                      </div>
                    </h4>
                    {{ Math.ceil((( +data.gold_price * +data.gold_weight) + (data.stones.reduce(
                      (p, item) => p + ( (+item.price * +item.weight) + +item.setting + +item.quantity),
                      0
                    ))) * +data.profit_percent) }}
                  </v-card>
                </div>
                <v-btn
                  v-if="data.category_id && data.category_id.id != 6"
                  type="submit"
                  class="mx-auto my-auto d-flex"
                  color="indigo"
                  :loading="loading"
                  :disabled="!valid || loading === true"
                  @click="submitForm()"
                >
                  {{ this.$route.params.id ? $t('actions.save') : $t('actions.add') }}
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
  <script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const mainStockServices = ServiceFactory.get('mainStock')
  const branchesService = ServiceFactory.get('branches')
  const stoneServices = ServiceFactory.get('stones')
  const categoryServices = ServiceFactory.get('category')
  const certificatesServices = ServiceFactory.get('certificates')
  const stonesService = ServiceFactory.get('stones')

  export default {
    name: 'MakeNewTransfer',
    // eslint-disable-next-line vue/require-prop-types
    props: ['itemDetails'],
    data: (vm) => ({
      menu: false,
      dataLoading: false,
      valid: false,
      data: {
        branch_id: {},
        category_id: {},
        gold_price: 0,
        gold_weight: 0,
        image: null,
        label: null,
        metal_type: null,
        product_date: null,
        profit_percent: 1,
        stones: [],
        certificate_type_id: null,
      },
      stoneDetails: {
        quantity: 0,
        weight: 0,
      },
      deleteDailog: false,
      productList: [],
      stoneTypes: [],
      branches: [],
      metalTypes: [],
      categories: [],
      certificates: [],
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      loading: false,
      disabled: false,
      globalRules: [
        v => !!v || 'data is required',
      ],
    }),
    computed: {
      today () {
        var todayDate = new Date().toISOString().slice(0, 10)
        return todayDate
      },
    },

    watch: {
      // stoneDetails () {
      //   this.data.stones.find(i => {
      //     if (this.stoneDetails.id === i.id) {
      //       const item = this.data.stones.indexOf(this.stoneDetails)
      //       this.data.stones.splice(item, 1)
      //       this.data.stones.push(this.stoneDetails)
      //     }
      //   })
      // },
    },
    created () {
      this.fetchAllBranches()
      this.fetchMetalTypes()
      this.fetchAllStonTypes()
      this.fetchAllcategories()
    },
    methods: {
      async getcode (id) {
        const item = await mainStockServices.GetProductCode(id)
        this.data.label = item.data
      },
      AddNewStone () {
        if (this.$refs.form.validate() === true) {
          this.data.stones.push(this.stoneDetails)
          this.stoneDetails = {}
        }
      },
      confirmDeleteItem (data) {
        this.stoneDetails = data
        this.deleteDailog = true
      },
      changeStone (data) {
        setTimeout(() => {
          // console.log('this.data.stones', this.data)
          this.editedIndex = this.data.stones.indexOf(data)
          this.data.stones.splice(this.editedIndex, 1)
        }, 500)
        this.stoneDetails = data
      },
      async deleteItem (stoneDetails) {
        this.deleteDailog = false
        setTimeout(() => {
          // console.log('this.data.stones', this.data)
          this.editedIndex = this.data.stones.indexOf(stoneDetails)
          this.data.stones.splice(this.editedIndex, 1)
        }, 500)
      },
      convertBase64 (file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReader.readAsDataURL(file)

          fileReader.onload = () => {
            resolve(fileReader.result)
            this.data.image = fileReader.result
          }
          fileReader.onerror = (error) => {
            reject(error)
          }
        })
      },
      uploadImags (event) {
        this.convertBase64(event)
      },
      async submitForm () {
        if (this.$refs.form.validate() === true) {
          this.loading = true
          this.disabled = true
          const formData = {
            branch_id: this.data.branch_id.id ? this.data.branch_id.id : null,
            category_id: this.data.category_id.id ? this.data.category_id.id : null,
            gold_price: this.data.gold_price,
            gold_weight: this.data.gold_weight,
            image: this.data.image,
            label: this.data.category_id.code && this.data.label ? this.data.category_id.code + this.data.label : null,
            metal_type: this.data.metal_type,
            product_date: this.data.product_date,
            profit_percent: this.data.profit_percent,
            stones: this.data.stones.length > 0 ? this.data.stones : [],
            certificate_type_id: this.data.certificate_type_id ? this.data.certificate_type_id.id : null,
          }
          this.newItem(formData)
        }
      },
      async newItem (formData) {
        // console.log('formData', formData)
        const item = await mainStockServices.AddNewItem(formData)
        console.log('item', item)
        try {
          if (item.status === 'success') {
            this.successMessage = 'product Added Successfuly'
            this.successSnackbar = true
            setTimeout(() => {
              this.$router.push('/stock/list')
            }, 1500)
          } else {
            this.errorMessage('something Error')
            this.errorSnackbar = true
          }
        } catch (err) {
          console.log('err', err)
          this.errorMessage('something Error')
          this.errorSnackbar = true
        }
        this.disabled = false
        this.loading = false
      },
      async fetchAllBranches () {
        this.dataLoading = true
        const list = await branchesService.getActiveList()
        this.branches = list.data
      },
      async fetchMetalTypes () {
        this.dataLoading = true
        const list = await stoneServices.getMetalTypes()
        this.metalTypes = list.data
      },
      async fetchAllcategories () {
        this.dataLoading = true
        const list = await categoryServices.getActiveList()
        this.categories = list.data
      },
      async fetchAllCertificates () {
        this.dataLoading = true
        const list = await certificatesServices.getAllItems()
        console.log('list', list)
        this.certificates = list
      },
      async fetchAllStonTypes () {
        this.dataLoading = true
        const list = await stonesService.getActiveList()
        this.stoneTypes = list.data
        this.dataLoading = false
      },
    },
  }
  </script>
  <style lang="scss">
    a{
    text-decoration: none;
    }
    .image{
      .product-img{
        border-radius: 50%;
      }
      .noImage{
        border: 1px solid rgb(78, 78, 78);
        width: 150px;
        height: 150px;
        border-radius: 50%;
        line-height: 150px;
        color: #4d4d4d;
        font-size: 15px;
      }
    }
    .width100{
      width: 130px;
    }
    .custom-loader {
      animation: loader 1s infinite;
      display: flex;
    }
    @-moz-keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @-o-keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  </style>
